<template>
	<table class="table table-hover" v-if="tests">
		<thead>
		<tr>
			<td>ID</td>
			<td>Date/time</td>
			<td>Version</td>
			<td>Status</td>
			<td>Bet</td>
			<td>Lines</td>
			<td>Spins</td>
			<td>RTP</td>
			<td>Actions</td>
		</tr>
		</thead>
		<tbody>
		<template v-for="(test, index) in tests">
			<tr @click="openTest(test.Id)" class="cursor-pointer">
				<td :class="{'align-middle': test.Status !== 'running'}" v-text="`#${test.Id}`"></td>
				<td :class="{'align-middle': test.Status !== 'running'}">
					<div class="text-small font-weight-bold text-nowrap" v-if="test.StartTime"
							 v-rel-date="test.StartTime"></div>
				</td>
				<td :class="{'align-middle': test.Status !== 'running'}">
					<div v-text="test.Version"></div>
					<div class="text-light text-small" v-text="`${test.Sha.substr(0, 5)}...`"></div>
				</td>
				<td :class="{'align-middle': test.Status !== 'running'}">
					<spinner-status-label :status="test.Status"></spinner-status-label>
					<div class="text-small text-danger" v-if="test.Status === 'error'"
							 v-text="test.ErrorMessage"></div>

					<div class="d-inline-flex">
						<div class="text-small mx-1" v-if="test.Summary" v-text="test.Summary.total_time"></div>
						<div class="text-small"> |</div>
						<div class="text-small font-weight-bold mx-1" v-if="test.Summary.speed > 0"
								 v-text="`${test.Summary.speed} spins/s`"></div>
					</div>

					<div class="text-center bg-light p-2" v-if="test.Status === 'running'">
						<div class="text-small font-weight-bold">Remaining</div>
						<hr>
						<div class="d-flex justify-content-center">
							<div class="mx-2 w-100">
								<div class="text-small text-light">Spins</div>
								<div class="text-small">{{ (test.Spins - test.Summary.spins) | numeral('0,0') }}
								</div>
							</div>
							<div class="mx-2 w-100">
								<div class="text-small text-light">Time</div>
								<div class="text-small">{{
										((test.Spins - test.Summary.spins) /
												Math.round(test.Summary.speed))
												| numeral('00:00:00')
									}}
								</div>
							</div>
						</div>
					</div>

					<div v-text="test.Notes" class="text-small bg-light p-2" v-if="test.Notes"></div>

				</td>
				<td :class="{'align-middle': test.Status !== 'running'}" v-text="test.Bet"></td>
				<td :class="{'align-middle': test.Status !== 'running'}" v-text="test.Paylines"></td>
				<td :class="{'align-middle': test.Status !== 'running'}">
					<div class="progress">
						<div class="progress-bar"
								 :style="{width: `${Math.round(test.Summary.spins / test.Spins * 100)}%`}"></div>
						<div class="progress-bar bg-dark progress-bar-striped progress-bar-animated"
								 v-if="test.Status === 'running'"
								 :style="{width: `${100 - Math.round(test.Summary.spins / test.Spins * 100)}%`}"></div>
					</div>
					<div class="text-small text-nowrap text-right text-uppercase">
						{{ test.Summary.spins | numeral("0a") }} / {{ test.Spins | numeral("0a") }}
					</div>
				</td>
				<td>
					<div v-if="test.Summary && test.Summary.balance.total_bet > 0" style="font-size: 1.4em;"
							 :class="rtpClass(test.Summary.balance.total_win / test.Summary.balance.total_bet)">
						{{ (test.Summary.balance.total_win / test.Summary.balance.total_bet * 100).toFixed(2) }}%
					</div>
				</td>
				<td class="d-inline-flex flex-nowrap align-items-center justify-content-end">
					<slot :test="test"></slot>

					<a :class="{'disabled text-light': !test.LogFile, 'text-primary': test.LogFile}"
						 class="btn btn-link"
						 @click.stop.capture target="_blank" :href="test.LogFile" v-if="test.SaveLogs || test.SaveSpinLogs">
						<i
								class="fa fa-download"></i></a>

					<router-link :key="`row-${test.Id}`" :to="{
								name: 'projects-editor-uuid-cuuid-tests-spinner-spinner_id',
								params: {
									uuid: $route.params.uuid,
									 cuuid: $route.params.cuuid,
									 spinner_id: test.Id
								}
					}" class="cursor-pointer">Details
					</router-link>
				</td>
			</tr>
		</template>
		</tbody>
	</table>
</template>

<script>
import SpinnerStatusLabel from '@/components/spinner/spinner-status-label'
import SpinnerTestSummary from '@/components/spinner/spinner-test-summary'
import SpinnerWinnings from "./spinner-winnings";
import SpinnerPaylineCombos from "./spinner-payline-combos";
import SpinnerPaylinesRtpContribution from "./SpinnerPaylinesRtpContribution";

export default {
	props: {
		tests: {
			default: [],
			type: Array
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			openTests: {},
		}
	},
	methods: {
		openTest(test_id) {
			const route = {
				name: 'projects-editor-uuid-cuuid-tests-spinner-spinner_id',
				params: {
					uuid: this.$route.params.uuid,
					cuuid: this.$route.params.cuuid,
					spinner_id: test_id
				}
			}
			this.$router.push(route)
		},
		rtpClass(rtp) {
			if (rtp > 0.94 && rtp < 0.97) {
				return 'text-success font-weight-bold'
			}
		},
		deleteTest(test_id) {
			this.$emit('deleteTest', test_id)
		}
	},
	components: {
		SpinnerPaylinesRtpContribution,
		SpinnerPaylineCombos,
		SpinnerWinnings,
		SpinnerStatusLabel,
		SpinnerTestSummary
	}
}
</script>
