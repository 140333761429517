<template>
	<div v-if="report" class="p-3 bg-light">

		<div class="d-flex">
			<div class="flex-grow-1">
				<h3>Report #{{report.Id}} summary</h3>
				<p v-text="report.Notes"></p>
			</div>
			<div v-if="canWrite('spinners')">
				<button class="btn btn-outline-danger" @click="deleteReport">DELETE REPORT</button>
			</div>
		</div>

		<div>
			<div>
				<span class="font-weight-bold">Tests: </span>
				<span v-for="spin in report.Spins" v-if="report.Type === 'standard'">{{spin|numeral('Oa')}} </span>
				<span v-if="report.Type === 'stress'">{{report.Spins[0]|numeral('Oa')}} tests for {{report.Spins[1]|numeral('Oa')}} spins</span>
			</div>

			<div>
				<span class="font-weight-bold">Paylines: </span>
				<span>{{report.Paylines.join(' / ')}}</span>
			</div>
		</div>

		<hr>
		<div>
			<spinner-test-summary :summary="report.Summary"></spinner-test-summary>
			<spinner-winnings v-model="options" :lines="report.Paylines.reduce((c, p) => c + parseInt(p), 0)"
							  :winnings="report.Summary.winnings_stats"></spinner-winnings>
		</div>

		<spinner-status-label :status="report.Status"></spinner-status-label>
		<div>
			<div>
				<strong>{{report.NbSpinnerTestsDone|numeral('0,0')}} of {{report.NbSpinnerTests|numeral('0,0')}}
					spinners done</strong>
			</div>
			<div class="progress">
				<div class="progress-bar"
					 :class="{'progress-bar-animated progress-bar-striped': report.Status === 'running'}"
					 :style="{width: `${((report.Summary.spins / totalSpins) * 100).toFixed(2)}%`}"></div>
			</div>
			<div class="text-small">
				<strong>{{report.Summary.spins|numeral('0,0')}} of {{totalSpins|numeral('0,0')}} spins done</strong>
			</div>
		</div>

		<div>
			<report-excel-download-button class="btn btn-success my-4"
										  :report="report">
				Download report Excel <i class="fa fa-file-excel ml-2"></i>
			</report-excel-download-button>
		</div>

		<hr>

		<div class="bg-white p-2" v-if="report.SpinnerTests && report.SpinnerTests.length > 0">
			<h4>Tests details</h4>
			<spinners-table v-model="options" :tests="report.SpinnerTests"></spinners-table>
		</div>
	</div>
</template>

<script>
	import api from '@/api'
	import SpinnersTable from "@/components/spinner/spinners-table";
	import SpinnerTestSummary from "@/components/spinner/spinner-test-summary";
	import ReportExcelDownloadButton from "@/components/reports/report-excel-download-button";
	import SpinnerStatusLabel from "@/components/spinner/spinner-status-label";
	import SpinnerWinnings from "@/components/spinner/spinner-winnings";

	export default {
		components: {
			SpinnerWinnings,
			SpinnerStatusLabel, ReportExcelDownloadButton, SpinnerTestSummary, SpinnersTable
		},
		data() {
			return {
				report: null,
				interval: null,
				options: {
					grouped: true,
					showAmount: false,
					groups: [50, 100, 500],
					rounded: 1
				}
			}
		},
		async mounted() {
			await this.refresh();
			this.interval = setInterval(this.refresh, 10000)
		},
		computed: {
			totalSpins() {
				if (this.report.Type === 'standard') {
					return this.report.Spins.reduce((c, s) => c + parseInt(s), 0) * this.report.Paylines.length;
				} else {
					return (this.report.Spins[0] * this.report.Spins[1]) * this.report.Paylines.length;
				}
			},
		},
		destroyed() {
			if (this.interval) {
				clearInterval(this.interval)
			}
		},
		methods: {
			async refresh() {
				this.report = await api.Reports.getOne(this.$route.params.report_id)
			},
			async deleteReport() {
				await api.Reports.delete(this.$route.params.report_id)
				this.$router.push({
					name: 'projects-editor-uuid-cuuid-tests-reports-index', params: {
						uuid: this.$route.params.uuid,
						cuuid: this.$route.params.cuuid,
					}
				})
			}
		}
	}
</script>
